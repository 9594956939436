.panel {
	border: #ccc 1px solid;
	margin: 0 auto;
	margin-top: 10%;
	padding: 15px;
	text-align: center;
	width: 350px;
	
	img {
		max-height: 70px;
	}
	
	form {
		div {
			display: block;
			margin: 0 auto;
			margin-bottom: 10px;
			width: 300px;
			
			label {
				color: #777;
				font-size: 10px;
				text-align: right;
				width: 40px;
			}
			
			input {
				border: 0 none;
				border-bottom: #777 1px solid;
				float: none;
				width: 300px;
			}
		}
	}
}