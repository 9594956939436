body {
  font-family: 'Open sans', 'sans-serif';
  margin: 0;
  color: #222;
}

$banner-bg-color: #f8f8f8;
$banner-border-color: #c0cc1e;
$light-grey : #ebebeb;
$washed-orange: #ffa500;

$banner-height: 60px;
$sidebar-bg-color: $banner-bg-color;
$sidebar-border-color: $banner-border-color;
$sidebar-width: 250px;

.main-banner {
  box-sizing: border-box;
  height: $banner-height;
  background-color: $banner-bg-color;
  border-bottom: 1px solid $banner-border-color;
  padding: 0 14px;

  justify-content: space-between;
  display: flex;

  .title {
    line-height: $banner-height;
    margin: 0;
  }
}

.sidebar {
  box-sizing: border-box;
  position: absolute;
  top: $banner-height;
  bottom: 0;
  width: $sidebar-width;
  background-color: $sidebar-bg-color;
  border-right: 1px solid $sidebar-border-color;

  .nav-item {
    a {
      text-decoration: none;
      color: inherit;
      display: block;
      padding: 10px 15px;

      &:hover, &.router-link-active {
        background-color: lighten($sidebar-bg-color, 20);
      }

      &:focus {
        background-color: lighten($sidebar-bg-color, 20);
        outline: none;
      }
    }
  }
}

.content {
  box-sizing: border-box;
  padding: 15px 30px;
  position: absolute;
  top: $banner-height;
  bottom: 0;
  left: $sidebar-width;
  right: 0;
  background-color: #fafafa;
}

.layout-client-index {
  .content {
    padding-top: 30px !important;

    footer {
      background: #ebebeb;
      padding: 2rem 1rem 1rem;
      margin: 0 -30px;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;


      li, p {
        font-size: 13px;
      }

      .address {
        flex: 1;

        h5 {
          font-weight: 600;
          color: #231f20;
          text-transform: capitalize;
        }

        a {
          text-decoration: none;
          color: #222;
        }

        ul {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .copyright {
        flex: 1;
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
    }

    @media only screen and (max-width: 760px) {
      padding: 2rem 2rem 0 !important ;
      footer {
        margin: 0 -2rem;
        text-align: center;
        display: block;
        .copyright {
          display: block;
          margin-top: 1rem;
        }
      }
    }

    .content-heading {
      font-weight: 500;
      color: black;
      font-size: 20px;
      margin-bottom: 1rem;
    }


 
  }
}

.button {
  background: #f0f0f0;
  color: #222;
  float: right;
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;

  &:focus {
    outline: none;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  &.state-on {
    background-color: #2e6ff8;
    color: #fafafa;
  }

  &.-standard {
    float: none;
    display: inline-block;
  }

  &.-wide {
    width: 100%;
    display: block;
    float: none;
    text-align: center;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: #f8f8f8;
    color: #777;
    text-transform: uppercase;
    border-top: 1px solid #eee;

    th {
      padding: 8px;
      text-align: center;
    }
  }

  tbody {
    border-top: 1px solid #eee;
  }

  th, td {
    border-right: 1px solid #eee;
    padding: 5px 10px;

    &:first-child {
      border-left: 1px solid #eee;
    }
  }

  tr {
    border-bottom: 1px solid #eee;
  }
}


.input-row {
  padding-top: 8px;
  padding-bottom: 8px;

  .input-label {
    display: block;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.95rem;
    padding-bottom: 8px;
  }

  input, textarea, select {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding-bottom: 7px;
    padding-top: 8px;
    font-size: 0.9rem;
    width: 30rem;
    font-family: inherit;
    background-color: transparent;

    &:focus {
      outline: none;
      border-bottom: 2px solid #2e6ff8;
      padding-bottom: 6px;
    }
  }

  select {
    padding-top: 7px;
  }

  .input-errors {
    display: none;
    font-size: 0.7rem;
    color: #fe234a;
    margin-top: 8px;
  }

  &.invalid {
    .input-label {
      color: #fe234a;
    }

    input, textarea, select {
      border-bottom-color: #fe234a;
      border-bottom-width: 2px;
      padding-bottom: 6px;
    }

    .input-errors {
      display: block;
    }
  }
}

.toasts {
  position: fixed;
  bottom: 30px;
  width: 100%;
  text-align: center;

  .toast {
    background-color: #444;
    padding: 14px 24px;
    border-radius: 2px;
    color: #f3f3f3;
    display: inline;
  }
}

.pos {
  padding-bottom: 160px;

  .pos-panel {
    padding: 15px 30px;
    background-color: white;
    margin-bottom: 30px;
    border-radius: 8px;

    &.disabled {
      opacity: 0.5;
    }

    > .title {
      margin: 0;
      text-transform: uppercase;
      opacity: 0.8;
      font-size: 1.1rem;
    }
  }
}

.summary-panel {
  padding: 30px 45px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: white;
  position: fixed;
  bottom: 0;
  left: $sidebar-width;
  right: 0;
  height: 100px;

  .total-price {
    float: right;
    font-weight: bold;
    font-size: 1.6rem;
  }

  .payment {
    padding-top: 25px;
    clear: both;
  }
}

.vehicle-results, .result-cards {
  margin-top: 16px;
}


.card-container {
  vertical-align: top;
  display: inline-block;
  width: 33%;

  padding: 0 .5%;

  @media only screen and (max-width: 760px) {
    width: 100%;

    &:last-child {
      margin-bottom: 3rem;
      hr {
        display: none;
      }
    }
  }

  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .button {
      width: 49%;
      margin-bottom: 4%;
      float: none;
      display: flex;
      margin: 0 0 2%;
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      line-height: 1.25;
      padding: 6px;
      text-transform: lowercase;
    }
  }

}

.result-card {
  background-color: #c0cc1e;
  margin-bottom: 16px;
   border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding-bottom: 10%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 97%;
    height: 90%;
    border: 1px solid #fff;
    border-radius: 5px;
    position: absolute;
    top: 3%;
    left: 1%;
    z-index: 2;
  }

  @media only screen and (max-width: 760px) {
    &:after {
      content: "";
      width: 96%;
      height: 92%;
      left: 1.5%;
    }
  }

  > .title {
    margin: 0;
    text-transform: uppercase;
    color: #231f20;
    font-weight: 600;
  
    @media only screen and (max-width: 760px) {
      font-size: 18vw;
    }
  
  }

  > .body {
    margin: 5px 0;
    font-size: 0.9rem;
    opacity: 0.7;
  }

  &.tight {
    margin: 0;
  }

  &.selected {
    background-color: #2e6ff8;
    color: white;
  }
}

.products-table {
  thead {
    border-top: none;
    background-color: transparent;

    th, td {
      border: none !important;
    }
  }

  th {
    text-align: left;
  }

  .subheader th {
    color: #777;
    text-transform: uppercase;
  }

  .add-on {
    border-bottom: none;

    th {
      font-weight: normal;
      padding-left: 30px;
    }

    td {
      text-align: center;
    }
  }

  .price {
    &.first {
      border-top: 1px solid #eee;
    }
  }

  tfoot {
    tr {
      text-align: center;
      border: none;

      td {
        border: none !important;

        button {
          float: none;
        }
      }
    }
  }
}

.add-on-table {
  .add-on-name {
    margin: 0;
  }

  .button {
    padding: 30px;
  }
}

.tabs {
  .tab-header {
    padding: 15px 25px;
    display: inline-block;
    text-align: center;
    border-bottom: 2px solid transparent;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    cursor: pointer;

    @media only screen and (max-width: 760px) {
			padding: 15px 4.5px;
			font-size: .85rem;
		}

    &.active {
      border-bottom-color: #2e6ff8;
    }
  }

  .tab-content {
    padding: 15px;
  }
}

.subscribe-total-panel {
  padding-left: 35px;
}


hr {
  margin: 1rem 0;
  display: block; 
  height: 1px;
  border: 0; 
  border-top: 1px solid $banner-border-color;
}

@import "styleguide/washed";

@import "components/button",
"components/panel",
"components/pos",
"components/table",
"components/modal",
"components/datepicker",
"components/filters",
"components/form",
"components/card-input",
"components/hamburger",
"components/grid",
"components/fw-flex";

@import "components/wash-card";

@import "structure/admin";

@import "subscribe/reusable",
        "subscribe/variables",
        "subscribe/mixins",
        "subscribe/styleguide",
        "subscribe/page-layout",
        "subscribe/subscribe-body";


.phpdebugbar {
  text-transform: initial;
}

.vehicle-addon-bar {
  min-height: 38px;
  width: 100%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.addon-list-wrapper {
  margin-top: 8px;

  .addon-list {
      display: flex;
      flex-direction: column;

      > div {
          margin-bottom: 5px;
      }
  }
}

.family-pass-plan {
  color: #ffa500;
  font-size: 18px;
}

.text-orange {
  color: $washed-orange;
}