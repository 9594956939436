.pos.summary-panel {
	height: auto;
	padding: 15px 30px;
	text-align: right;
	
	.payment {
		display: inline-block;
		float: right;
		padding: 0;
		padding-left: 20px;
		
		.button {
			float: none;
		}
	}
	
	.total-price {
		display: inline-block;
	}
}