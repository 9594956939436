

@mixin font-normal {
    font-family: $font;
    font-weight: 400;
}
@mixin font-bold {
    font-family: $font;
    font-weight: 700;
}
@mixin font-color {
    color: $text-black;
}


@mixin h1($with-margins: false) {
    @include font-normal;
    @include font-color;
    font-size: 25px;
    line-height: 1.1;
    @if ($with-margins) {
        margin: -5px 0 10px;
    }
}
@mixin h2($with-margins: false) {
    @include font-bold;
    @include font-color;
    font-size: 20px;
    line-height: 1.2;
    @if ($with-margins) {
        margin: -5px 0 8px;
    }
    @media (min-width: 350px) {
        font-size: 22px;
    }
}
@mixin h3($with-margins: false) {
    @include font-normal;
    @include font-color;
    font-size: 18px;
    line-height: 1.2;
    @if ($with-margins) {
        margin: -5px 0 6px;
    }
    @media (min-width: 350px) {
        font-size: 20px;
    }
}
@mixin h4($with-margins: false) {
    @include font-bold;
    @include font-color;
    font-size: 15px;
    line-height: 1.3;
    @if ($with-margins) {
        margin: -5px 0 4px;
    }
    @media (min-width: 350px) {
        font-size: 16px;
    }
}
@mixin h5($with-margins: false) {
    @include font-normal;
    @include font-color;
    font-size: 15px;
    line-height: 1.4;
    @if ($with-margins) {
        margin: -5px 0 4px;
    }
    @media (min-width: 350px) {
        font-size: 16px;
    }
}
@mixin p($with-margins: false) {
    @include font-normal;
    @include font-color;
    font-size: 14px;
    line-height: 1.5;
    @if ($with-margins) {
        margin: -5px 0 4px;
    }
    @media (min-width: 350px) {
        font-size: 15px;
    }
}
@mixin p-small($with-margins: false) {
    @include font-normal;
    @include font-color;
    font-size: 12px;
    line-height: 1.5;
    @if ($with-margins) {
        margin: -5px 0 3px;
    }
    @media (min-width: 350px) {
        font-size: 13px;
    }
}


//@mixin input-and-select-style {
//    @include p;
//    font-family: $font;
//    border: none;
//    height: 34px;
//    border-radius: 4px;
//    width: 100%;
//    box-shadow: none;
//    background-color: $input-bg;
//    color: $text-black;
//    &:focus {
//        box-shadow: none;
//        outline: none;
//    }
//}
//@mixin input-style {
//    @include input-and-select-style;
//    padding: 8px 10px 10px;
//    &::placeholder {
//        color: $text-faint;
//    }
//}
//@mixin select-style {
//    @include input-and-select-style;
//    padding: 5px 28px 6px 6px;
//}


@mixin pos-panel-content-width {
    width: 100%;
    @media (min-width: 430px) {
        max-width: 100%;
    }
}

