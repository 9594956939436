

.subscribe-body {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-transform: none;
    max-width: 700px;
    @include margin-x(auto);
    > header {
        padding: 30px $body-pad-x 36px;
        background-color: #fff;
        .-logo {
            display: block;
            width: 150px;
            margin-bottom: 40px;
        }
        h2 {
            margin-bottom: 8px;
        }
        h3 {
            margin-bottom: 0;
        }
    }
    main {
        padding: $body-pad;
        background-color: $bg-chartreuse;
        .pos-panel {
            padding: 16px $pos-panel-pad-x;
            h4 {
                margin-bottom: 25px;
            }
            .input-row {
                @include pos-panel-content-width;

                .single {
                    max-width: 135px;
                }
            }
            > :last-child {
                &, > :last-child {
                    margin-bottom: 0;
                }
            }
            .wash-cards {
                .wash-card {
                    @include pos-panel-content-width;
                    border-radius: $wash-card-radius;
                    display: flex;
                    flex-direction: row-reverse;
                    height: $wash-card-height;
                    padding: 0;
                    margin: 0 0 22px;
                    align-items: stretch;
                    min-height: 0;
                    .options, .meta {
                        display: inline-flex;
                        height: $wash-card-height;
                        position: static;
                    }
                    .options {
                        width: $wash-card-height;
                        flex: 0 0 auto;
                        > div {
                            display: flex;
                            button {
                                margin: -1px 0 0 -1px;
                                border-radius: $wash-card-radius;
                                border: $stroke;
                                display: flex;
                                width: 100%;
                                float: none;
                                height: 100%;
                                align-items: center;
                                background-color: #fff;
                                border: $stroke;
                                &.state-on {
                                    border-color: $bg-chartreuse;
                                    background-color: $bg-chartreuse;
                                    box-shadow: none;
                                    &.-orange {
                                        background-color: $washed-orange;
                                        color: white;
                                    }
                                }
                                .normal-text {
                                    @include h4;
                                    width: 100%;
                                    text-align: center;
                                    &.-orange {
                                        color: white;
                                    }
                                    span {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .meta {
                        width: 100%;
                        flex: 0 1 auto;
                        padding: 5px 8px 5px 12px;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        margin: 0;
                        h2 {
                            @include h4;
                            margin-bottom: 5px;
                        }
                        .add-ons {
                            @include p-small;
                        }
                    }
                }
            }
            &.disabled {
                padding: 20px $pos-panel-pad-x 16px;
                > h4 {
                    margin-bottom: 8px;
                }
            }
            &.-order-summary {
                table {
                    @include pos-panel-content-width;
                    margin: 10px 0 0 -1px;
                    tr {
                        vertical-align: top;
                        td {
                            line-height: 1.2;
                            padding: 0 0 12px;
                        }
                        td:last-child {
                            text-align: right;
                        }
                    }
                    tr.-total {
                        td {
                            font-weight: bold;
                        }
                    }
                }
            }
            &.-payment-info {
                button.button {
                    @include h3;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 12px;
                    border-radius: 6px;
                    background-color: $bg-chartreuse;
                    margin: 12px 16px 16px;
                    width: calc(100% - 32px);
                    float: none;
                    user-select: none;
                    &:focus {
                        box-shadow: none;
                    }
                    &.-disable {
                        pointer-events: none;
                        opacity: 0.3;
                    }
                }
            }
        }
    }
    .credit-card-input {
        @include pos-panel-content-width;
        .-agree {
            display: flex;
            align-items: flex-start;
            padding-top: 5px;
            .-cb {
                display: inline-block;
                width: 30px;
                height: 30px;
                flex: 0 0 auto;
                position: relative;
                background-color: $input-bg;
                border-radius: 6px;
                cursor: pointer;
                img {
                    position: absolute;
                    top: -4px;
                    width: 30px;
                    left: 5px;
                }
            }
            p {
                flex: 0 1 auto;
                width: 100%;
                margin-left: 10px;
            }
        }
    }
}

.pos {
    .locations {
        background-color: white !important;

        input {
            border: 2px solid #ccc;
            border-radius: 10px;
            margin-right: 10px;
            cursor: pointer;
        }

        .selected {
            background-color: #cddc29;
        }
    }
}








