.fw-flex {
    width:100%;
    justify-content:space-between;
    align-items:center;
    display:flex;
}

.coupon-notice {
    background-color: $washed-gray;
    color: #fff;
    padding:4px 16px;
    margin-bottom:4px;
    &.small {
        padding:1px 4px;
        p {
            margin-bottom:2px;
        }
    }
    p {
        text-align:right;
        margin-top:2px;
    }
}

.subscription-id {
    input {
        font-family: monospace;
        font-size: 12px;
        width: 90%;
        border: none;
        padding: 4px;
        &:focus {
            color: #F3525A;
            font-weight: bold;
        }
    }
}

.clearfix {
    &::after {
        content:"";
        clear:both;
        display: table;
    }
}