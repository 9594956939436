

$font: 'Open sans', 'sans-serif';

$bg-chartreuse: #cddc29;
//$bg-chartreuse: #70a743;

$input-bg: #dfdcdc;
$text-black: #4f5052;
$text-faint: lighten($text-black, 10%);

$light-grey : #ebebeb;

$stroke-color: #bbb;
$stroke: 1px solid $stroke-color;

$body-pad-x: 22px;
$body-pad-y: 20px;
$body-pad: $body-pad-y $body-pad-x;

$wash-card-radius: 30px;
$wash-card-height: 125px;

$pos-panel-pad-x: 18px;
