.wash-card {
     
    vertical-align: top;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    display: inline-block;
    width: 180px;
    margin-right: 20px;
    margin-bottom: 16px;
    padding: 15px 25px;
    cursor: pointer;
    
    > .title {
        margin: 0;
    }
    
    > .body {
        margin: 5px 0;
        font-size: 0.9rem;
        opacity: 0.7;
    }
    
    &.tight {
        margin: 0;
    }
    
    &.selected {
        background-color: #2e6ff8;
        color: white;
    }
     

    // min-height: 400px;
    min-height: 200px;
    position:   relative;
    width:      30%;
    padding-bottom: 50px;

    .image {
        background-size: cover;
        display:         block;
        min-height:      250px;
        margin:          -15px -25px;
        overflow:        hidden;
    }

    .meta {
        margin-bottom: 15px;

        h2 {

        }
    }

    .options {
        bottom:   10px;
        display:  block;
        position: absolute;
        left:     10px;
        right:    10px;

        div {
            button {
                display: inline-block;
                // margin-right: 3%;
                // width: 45%;
                width:   100%;
            }

            &:not(:last-child) {
                button {
                    // float: left;
                }
            }
        }
    }

    @media (max-width: 740px){

        min-height: 200px;
        position:   relative;
        width: 80%;

        .image {
            background-size: cover;
            display:         block;
            min-height:      250px;
            margin:          -15px -25px;
            overflow:        hidden;
        }

        .meta {
            margin-bottom: 15px;

            h2 {

            }
        }

        .options {
            bottom:   10px;
            display:  block;
            position: absolute;
            left:     10px;
            right:    10px;

            div {
                button {
                    display: inline-block;
                    // margin-right: 3%;
                    // width: 45%;
                    width:   100%;
                }

                &:not(:last-child) {
                    button {
                        // float: left;
                    }
                }
            }
        }
    }
}

