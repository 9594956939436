.regSection {
	> .input-row {
		display:     inline-block;
		margin-right: 10px;
		vertical-align: top;
	}
}

.input-row {
	padding-top: 8px;
	padding-bottom: 8px;
	width: 100%;
	max-width: 30rem;

	.input-label {
		display: block;
		vertical-align: top;
		color: rgba(0, 0, 0, 0.6);
		font-size: 0.95rem;
		padding-bottom: 8px;
	}

	.cov-vue-date {
		width: 100%;
	}

	input, textarea, select {
		border: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.4);
		width: 100%;
		padding-bottom: 7px;
		padding-top: 8px;
		font-size: 0.9rem;
		font-family: inherit;
		background-color: transparent;

		&:focus {
			outline: none;
			border-bottom: 2px solid #2e6ff8;
			padding-bottom: 6px;
		}

		&.short-input {
			width: 15rem;
		}
	}

	.input-errors {
		display: none;
		font-size: 0.7rem;
		color: #fe234a;
		margin-top: 8px;
	}

	&.invalid {
		.input-label {
			color: #fe234a;
		}

		input, textarea, select {
			border-bottom-color: #fe234a;
			border-bottom-width: 2px;
			padding-bottom: 6px;
		}

		.input-errors {
			display: block;
		}
	}
}

.block-form {
	width: 30rem;

	.input-row {
		padding-left: 5px;
		padding-right: 5px;
		width: auto;
	}
}

.add-users {
	display: flex;
	.users,.roles {
		display: flex;
		align-items: center;
		margin-right: 40px;

		label {
			margin-right: 12px;
		}
	}
}
