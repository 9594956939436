.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  .cover {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal {
    position: absolute;
    z-index: 120;
    display: inline-block;
    width: 600px;
    padding: 40px;
    background-color: #fff;
    max-width: 95vw;
  }
  @media (max-width: 620px) {
    .modal {
      padding: 6.5vw;
    }
  }
}



