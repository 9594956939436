@import '../styleguide/_washed.scss';

.button {
	background: $washed-yellow;
	cursor: pointer;
	font-family: 'Open sans', sans-serif;
	color: #222;
	float: right;
	border: none;
	border-radius: 3px;
	padding: .25rem .5rem;
	text-transform: uppercase;
	font-weight: bold;
	text-decoration: none;
	font-size: 0.8rem;
	margin: 0 .25rem;

	&.-grey {
		background: $light-grey;
	}

	&.-orange {
		background-color: $washed-orange !important;
		color: white !important;
	}

	&:focus {
		outline: none;
		box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	}

	&:hover {
		background: darken($washed-yellow, 5%);
	}

	&.hollow {
		background: transparent;
		border: 1px solid $washed-yellow;
	}

	&.state-on {
		background-color: #2e6ff8;
		color: #fafafa;

		&:hover {
			background: darken(#2e6ff8, 5%);
		}
	}

	&.cancelable {
		.cancel-text {
			display: none;
		}

		&.state-on:hover {
			background: #db2323;
			color: white;

			.normal-text {
				display: none;
			}

			.cancel-text {
				display: inline;
			}
		}
	}

	&.no-float {
		float: none;
	}

	&[disabled] {
		background-color: #CCC;
		cursor: default;
	}

	+ .button {
		margin-left: 4px;
	}
}

.close-button {
	background: none;
	border: none;
	outline: none;
	float: right;
	color: rgba(0, 0, 0, 0.5);
	margin-top: -3px;
	margin-right: -15px;
	cursor: pointer;
}