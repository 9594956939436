$nav-width: 120px;
$mobile-nav-width: 88px;

body {
	overflow: hidden;
	// text-transform: uppercase;
	
	.main-banner {
		background: #ffffff;
		overflow:   hidden;
		
		.title {
			img {
				max-height: 50px;
				padding:    5px;
			}
		}
	}
	
	nav.sidebar {
		background: $washed-gray;
		width:      $nav-width;
		left: 0;
		transition: left .5s;
		z-index: 2;
		overflow-y: auto;


		@media only screen and (max-width: 760px) {
			left: - 100vw;
			width: 100vw;
			
			&.-slide-in {
				left: 0;
			}

			.nav-item {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
 			}
		}

		a {
			color:          #797979 !important;
			cursor:         pointer;
			font-size:      11px;
			padding:        15px 0 !important;
			text-align:     center;
			text-transform: uppercase;
			
	
			span {
				display:       block;
				font-size:     24px;
				margin-bottom: 5px;
			}
			
			@media only screen and (max-width: 760px) {
				padding: 8px 0 !important; 

				span {
					font-size: 18px;
				}
			}


			&.router-link-active {
				background: $washed-yellow !important;
				color:      #333333 !important;
			}
		}
	}
	
	.content {
		left:    $nav-width;
		overflow: auto;
		padding: 0 30px !important;

		@media only screen and (max-width: 760px) {
			padding: 15px !important;
			left: 0;
			transition: .5s left;
		}
	}
	
	.summary-panel {
		left: $nav-width;
	}
}

h2 {
	small {
		opacity: .5;
	}
}