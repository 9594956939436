.table-filters {
	margin-bottom: 15px;
	display: flex;
	flex-wrap: wrap;
	
	.input-row {
		display: inline-block;
		flex: 0 0 16.6666%;
		
		input {
			max-width: 100%;

			&[type=checkbox] {
				height: 1.5em;
				width: 1.5em;
			}
		}
	}
}
.washes {
	.table-filters {
		.input-row:first-child {
			margin-left: 0;
		}
		.input-row {
			margin-left: 4px;
		}
	}
}
.table {
	background: #fff !important;
	
	th {
		font-size: 12px;
		text-align: left;
	}
	
	td {
		font-size: 12px;
		
		&.right {
			text-align: right;
		}
	}



	@media only screen and (max-width: 760px) {

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 2px solid $washed-gray;
            border-top: 2px solid $washed-gray;
            display: block;
            margin-bottom: 2em;
			padding: 1rem 0;

            td {
                display: block;
                font-size: 14px;
                text-align: right;
				clear: both;

				&:first-child {
					border-left	: none;
				}

                &::before {
                    content: attr(data-label);
                    float: left;
                } 
            }
        }

   
    }
}