


@mixin margin-x($val) {
    margin-left: $val;
    margin-right: $val;
}
@mixin padding-x($val) {
    padding-left: $val;
    padding-right: $val;
}

$inputs: '[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea';
$text-els: 'h1, h2, h3, h4, h5, p, li, td, th';


@mixin space-between {
    display: flex;
    justify-content: space-between;
}

.space-between {
    @include space-between;

    &-centered {
        @include space-between;
        align-items: center;
    }
}


@mixin vertical-scroll {
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 6px;
        -webkit-appearance: none;
    }
    &::-webkit-scrollbar:horizontal {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 8px;
    }
}

 .show-on-mobile {
    display: none;
    @media only screen and (max-width: 760px) {
        display: block;
    }
}

 .hide-on-mobile {
    display: block;
    @media only screen and (max-width: 760px) {
        display: none;
    }
}



@for $i from 0 through 15 {
    body {
        .m-t-#{$i} {
            margin-top: #{$i}rem;
        }

        .m-b-#{$i} {
            margin-bottom: #{$i}rem;
        }

        .m-b-#{$i}-half {
            margin-bottom: #{$i + .5}rem;
        }

        .m-r-#{$i} {
            margin-right: #{$i}rem;
        }

        .m-l-#{$i} {
            margin-left: #{$i}rem;
        }

        .m-#{$i} {
            margin: #{$i}rem;
        }

        .m-y-#{$i} {
            margin: #{$i}rem auto;
        }

        .m-x-#{$i} {
            margin: auto #{$i}rem;
        }

        .p-t-#{$i} {
            padding-top: #{$i}rem;
        }

        .p-t-#{$i}-half {
            padding-top: #{$i}rem;
        }

        .p-b-#{$i} {
            padding-bottom: #{$i}rem;
        }

        .p-b-#{$i}-half {
            padding-bottom: #{$i}rem;
        }

        .p-#{$i} {
            padding: #{$i}rem;

            &.cell {
                padding: #{$i}rem;
            }
        }

        .p-l-#{$i}-half {
            padding: #{$i + .5}rem;

            &.cell {
                padding: #{$i  + .5}rem;
            }
        }

        .p-y-#{$i} {
            padding-top: #{$i}rem;
            padding-bottom: #{$i}rem;
        }

        .p-y-#{$i}-half {
            padding-top: #{$i + .5}rem;
            padding-bottom: #{$i + .5}rem;
        }

        .p-x-#{$i} {
            padding-left: #{$i}rem;
            padding-right: #{$i}rem;
        }

        .p-x-#{$i}-half {
            padding-left: #{$i + .5}rem;
            padding-right: #{$i + .5}rem;
        }

        .p-l-#{$i} {
            padding-left: #{$i}rem;
        }

        .p-l-#{$i}-half {
            padding-left: #{$i + .5}rem;
        }

        .p-r-#{$i} {
            padding-right: #{$i}rem;
        }

        .p-r-#{$i}-half {
            padding-right: #{$i + .5}rem;
        }
    }
}

.no-bullets {
    padding: 0;
    list-style: none;
}

.h-100 {
    min-height: 100%;
}

.card-container {
    position: relative;
    .trash {
        position: absolute;
        top: 8px;
        right: 14px;
        z-index: 2;
        cursor: pointer;
        font-size: 29px;
        color: #767676;
    }

    .trash:hover {
        color: black;
    }
}
