 #hamburger  {
    width: 30px;
    height: 24px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    align-self: center;
    display: none;

    @media only screen and (max-width: 760px) {
        display: block
    }


    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #6d6d6d;
        border-radius: 3px;
        opacity: 1;
        left: 0;
        align-self: center;
    
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(1) {
            top: 0px;
        }

        &:nth-child(2) {
            top: 10px;
            width: 80%;
            left: 20%;
        }

        &:nth-child(3) {
            top: 20px;
        }
    }

    &.open {
        span:nth-child(1){
            top: 12px;
            transform: rotate(-135deg);
        }
        span:nth-child(3)  {
            top: 12px;
            transform: rotate(135deg);
        }
        span:nth-child(2) {
            opacity: 0;
            left: -60px;
        }
    }
}