

h1 {
    @include h1(true);
}
h2 {
    @include h2(true);
}
h3 {
    @include h3(true);
}
h4 {
    @include h4(true);
}
h5 {
    @include h5(true);
}
p, li, td, th {
    @include p(true);
}
