.cov-date-body {
  background-color: $washed-gray !important;
  font-family: "Open sans", sans-serif !important;

  .cov-date-monthly .cov-date-caption {
    br {
      display: none;
    }

    span {
      color: $washed-yellow !important;
      text-transform: uppercase;
      font-size: 24px !important;
      font-weight: bold;
      display: block;

      small {
        font-size: inherit !important;
      }

      &:hover {
        color: lighten($washed-yellow, 15) !important;
      }
    }
  }

  .cov-date-box .cov-picker-box .week li {
    text-transform: uppercase;
  }

  .button-box span {
    text-transform: uppercase;
    font-weight: bold;
  }
}