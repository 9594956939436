.table-filters {
  > div {
    display: flex;
    flex-wrap: wrap;
  }

  .input-row {

    input, textarea, select {
      width: 13rem;
      display: block;
    }

    .button {
      background-color: transparent;
    }
  }
}