@for $i from 1 through 12 {
  .col-#{$i} {
    width: 100% * ($i / 12);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
}

@for $i from 1 through 12 {
  .scol-#{$i} {
    width: 100% * ($i / 12);
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
  }
}

