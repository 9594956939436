
body.layout-client-index {
    overflow: auto;
    //width: 100%;
    //height: 100%;
    //position: absolute;
    //div {
    //    height: 100%;
    //    width: 100%;
    //    position: absolute
    //}
    .wash-card {
        box-shadow: none;
        border: $stroke;
        border-radius: 10px;
    }
    box-sizing: border-box;
    * {
        box-sizing: inherit;
    }
    .input-row {
        border-radius: 8px;
        background-color: $input-bg;
        padding: 5px 12px;
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        label {
            @include p;
            padding: 0;
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
        }
        #{$inputs} {
            display: inline-block;
            border: none;
            padding: 4px 5px 4px 10px;
            margin: 0 0 0 5px;
        }
        .input-errors {
            margin: 0;
        }
    }
}




